import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { useTranslation } from 'react-i18next'

const featureData = [
  {
    icon: '/images/icon-always-near.svg'
  },
  {
    icon: '/images/icon-last-minute.svg'
  },
  {
    icon: '/images/icon-best-price.svg'
  }
]

const FeatureCardsContainer = styled.div`
  width: 100vw;
  max-width: 1300px;
  margin: 50px auto;
  margin-bottom: 120px;
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: center;
  align-items: baseline;
  gap: 20px;

  @media only screen and (max-width: ${themeGet('breakpoints.1')}px) {
    flex-direction: column;
    align-items: center;
  }

  // homepage searchbar absolute element so, margin set high value
  // dynamic landing page searchbar static element so, margin set low value

  margin-top: ${(props) => (props.isPageDynamic === true ? '50px' : '840px')};

  @media only screen and (min-width: ${themeGet('breakpoints.1')}px) {
    margin-top: ${(props) => (props.isPageDynamic === true ? '50px' : '680px')};
  }

  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    margin-top: ${(props) => (props.isPageDynamic === true ? '50px' : '900px')};
  }
`
const FeatureCardsContainerWithSessionTag = styled(FeatureCardsContainer).attrs({
  as: 'section'
})``

const SingleFeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 30%;
  max-width: 450px;
  color: ${themeGet('colors.textBlack')};
  font-size: 20px;
  padding: 0 28px;
  gap: 0px;
  text-align: center;

  .title-and-img {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    gap: 15px;
    margin-left: 0;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.1')}px) {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font-size: 18px;
    margin-top: -30px;
    width: 70%;
    max-width: 750px;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font-size: 18px;
    margin-top: -30px;
    width: 95%;
    max-width: 750px;
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 25px;
  font-family: Montserrat;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #525266;

  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    font-size: 20px;
  }
`

const CardImg = styled.img`
  display: block;
  margin: 25px auto;
  margin-top: 35px;

  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    height: 65px;
    width: auto;
  }
`

const SeperatorLine = styled.div`
  width: 0;
  height: 0;
  border: 1px solid rgba(255, 125, 14, 0.6);

  @media only screen and (max-width: ${themeGet('breakpoints.1')}px) {
    margin-top: 10px;
    width: 30vw;
    height: 1px;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    width: 60vw;
    height: 1px;
  }
`

const Text = styled.div`
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-top: ${(props) => (props.isPageDynamic !== true ? '-13px' : '')};

  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    font-size: 18px;
    margin-top: -22px;
  }
`

function FeatureCards({ memorialDay, italy, isPageDynamic, data, spring }) {
  const [dynamicData, setDynamicData] = useState([])
  const { t } = useTranslation()
  const localizedFeatures = [
    {
      header: t('homePage:alwaysNearYou:header'),
      text: t('homePage:alwaysNearYou:text')
    },
    {
      header: t('homePage:rentLastMinute:header'),
      text: t('homePage:rentLastMinute:text')
    },
    {
      header: t('homePage:bestPrices:header'),
      text: t('homePage:bestPrices:text')
    }
  ]

  useEffect(() => {
    if (isPageDynamic) {
      if (italy) {
        setDynamicData([
          {
            icon: '/images/icon-always-near.svg',
            title: 'Sempre vicino a te',
            text: "Trovate un'auto a noleggio in più di 200 località in Italia. "
          },
          {
            icon: '/images/icon-last-minute.svg',
            title: "Noleggiare un'auto in qualsiasi momento.",
            text: "Avete bisogno di un'auto per godervi l'Italia? Siete nel posto giusto, iniziate la ricerca."
          },
          {
            icon: '/images/icon-best-price.svg',
            title: 'I prezzi più bassi in Italia.',
            text: 'Collaboriamo con le società di noleggio auto in Italia per offrirvi tariffe esclusive.'
          }
        ])
      }

      if (memorialDay) {
        setDynamicData([
          {
            icon: '/images/icon-always-near.svg',
            title: 'Always near you',
            text: 'Find a rental car at more than 200 locations for the Memorial Day weekend.'
          },
          {
            icon: '/images/icon-last-minute.svg',
            title: `Rent a car ${data.secondName}`,
            text: 'Planning a trip on Memorial Day Weekend? You are at the right place. Find the best car and hit the roads.'
          },
          {
            icon: '/images/icon-best-price.svg',
            title: 'Cheapest Prices on Best Cars',
            text: 'Car Hire Locations in or near Orlando'
          }
        ])
      } else {
        setDynamicData([
          {
            icon: '/images/icon-always-near.svg',
            title: 'Always near you',
            text: `${
              spring
                ? 'Find the cheapest car hire prices at 200 locations.'
                : `Find a rental car at more than 200 locations in or near ${data.name}.`
            }`
          },
          {
            icon: '/images/icon-last-minute.svg',
            title: `${spring ? 'Car Hire at Any Time' : `Rent a car ${data.secondName}`}`,
            text: `${
              spring
                ? 'Planning a trip on Easter or Bank Holiday? Or maybe just a weekend trip? Find the best car and hit the roads.'
                : `Need a car to enjoy ${data.attr}? You are at the right place, just start searching.`
            }`
          },
          {
            icon: '/images/icon-best-price.svg',
            title: `${spring ? 'Cheapest Prices on Best Cars' : `Cheapest prices in ${data.name}`}`,
            text: `${
              spring
                ? 'We partner with car hire companies in most popular locations.'
                : `We partner with car rental companies in ${data.name} to get you exclusive rates.`
            } `
          }
        ])
      }
    }
  }, [data])

  return (
    <FeatureCardsContainerWithSessionTag isPageDynamic={isPageDynamic}>
      {!isPageDynamic
        ? featureData.map(({ icon }, i) => (
            <React.Fragment key={i}>
              {i !== 0 && <SeperatorLine />}
              <SingleFeatureCard>
                <div className='title-and-img'>
                  <Title>{localizedFeatures[i].header}</Title>
                  <CardImg src={icon} alt='icon' height='50px' width='auto' />
                </div>
                <Text isPageDynamic={false}>{localizedFeatures[i].text}</Text>
              </SingleFeatureCard>
            </React.Fragment>
          ))
        : dynamicData.map(({ icon, title, text }, i) => (
            <React.Fragment key={i}>
              {i !== 0 && <SeperatorLine />}
              <SingleFeatureCard>
                <div className='title-and-img'>
                  <Title>{title}</Title>
                  <CardImg src={icon} alt={title} height='50px' width='auto' />
                </div>
                <Text isPageDynamic={true}>{text}</Text>
              </SingleFeatureCard>
            </React.Fragment>
          ))}
    </FeatureCardsContainerWithSessionTag>
  )
}

export default FeatureCards
